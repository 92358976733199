import jquery from "jquery";
import "bootstrap";
import "core-js/actual/string/includes";

(window as any).$ = jquery;

// IE11 sucks (Doesn't have String.includes)
if (!String.prototype.includes) {
  String.prototype.includes = function (search, start) {
    "use strict";
    if (typeof start !== "number") {
      start = 0;
    }
    if (start + search.length > this.length) {
      return false;
    } else {
      return this.indexOf(search, start) !== -1;
    }
  };
}

function loadContactUsInformation() {
  var targetNode = document.getElementById("contactUsAjaxTarget");
  while (targetNode && targetNode.firstChild) {
    targetNode.removeChild(targetNode.firstChild);
  }

  if (targetNode === null) {
    console.error("Couldn't find contactUsAjaxTarget. Exiting.");
    return;
  }

  var contactUsLoading = document.createElement("h5");
  contactUsLoading.className = "font-weight-bold";
  var contactUsLoadingText = document.createTextNode("...");

  contactUsLoading.appendChild(contactUsLoadingText);
  targetNode.appendChild(contactUsLoading);
  var xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function () {
    if (this.readyState == 4) {
      // Remove old stuff
      while (targetNode && targetNode.firstChild) {
        targetNode.removeChild(targetNode.firstChild);
      }
      // Container element
      var contactUsFlagContainer = document.createElement("div");
      contactUsFlagContainer.classList.add("pt-2");
      if (this.status == 200) {
        // Update all the contact information
        var responseObj = JSON.parse(this.responseText).contactUs as unknown;

        console.log("Response:", this.responseText, responseObj);
        var objKeys = Object.keys(responseObj);
        for (var x = 0; x < objKeys.length; x++) {
          const currKey = objKeys[x];
          const countryObj = responseObj[currKey];
          var outermostDiv = document.createElement("div");
          outermostDiv.classList.add("d-inline-flex");
          outermostDiv.classList.add("w-50");
          outermostDiv.classList.add("pb-4");
          if (x % 2 === 1) {
            outermostDiv.classList.add("pl-2");
          }
          var flagImage = document.createElement("img");
          flagImage.classList.add("flagicon");
          flagImage.classList.add("align-self-start");
          flagImage.classList.add("mr-2");

          const resourcesPath =
            document.body.getAttribute("data-resourcesPath");

          let defaultLanguageMap = {
            fi: "fi",
            se: "sv",
            no: "no",
            dk: "da",
          };

          switch (currKey) {
            case "dk":
              flagImage.src = `${resourcesPath}/img/flag_denmark.png`;
              break;
            case "fi":
              flagImage.src = `${resourcesPath}/img/flag_finland.png`;
              break;
            case "no":
              flagImage.src = `${resourcesPath}/img/flag_norway.png`;
              break;
            case "se":
              flagImage.src = `${resourcesPath}/img/flag_sweden.png`;
              break;
          }

          var span1 = document.createElement("span");
          span1.classList.add("d-flex");
          span1.classList.add("flex-column");
          span1.classList.add("align-text-top");
          var span2_1 = document.createElement("span");
          span2_1.classList.add("pb-1");
          span2_1.id = currKey + "Phone";

          const langTag = document.body.getAttribute("data-langTag");

          var span2_1_text = document.createTextNode(countryObj.phone);

          if (countryObj.extraPhoneInformation) {
            span2_1_text = document.createTextNode(
              countryObj.phone + " " + countryObj.extraPhoneInformation
            );
          }
          span2_1.appendChild(span2_1_text);
          var span2_2 = document.createElement("span");
          if (countryObj.email) {
            span2_2.classList.add("pb-1");
            span2_2.id = currKey + "Email";
            var span2_2_text = document.createTextNode(countryObj.email);
            span2_2.appendChild(span2_2_text);
          }
          var span2_3 = document.createElement("span");
          span2_3.id = currKey + "OpenHours";

          // Default to dominant language in countries
          var span2_3_text = document.createTextNode(
            countryObj.openTimes[langTag] ||
              countryObj.openTimes[defaultLanguageMap[currKey]]
          );

          span2_3.appendChild(span2_3_text);
          outermostDiv.appendChild(flagImage);
          span1.appendChild(span2_1);
          span1.appendChild(span2_2);
          span1.appendChild(span2_3);
          outermostDiv.appendChild(span1);
          contactUsFlagContainer.appendChild(outermostDiv);
        }
      } else {
        /* If the contact us request failed */
        var outermostDiv = document.createElement("div");
        outermostDiv.classList.add("pb-4");
        var fetchFailed = document.createElement("div");
        fetchFailed.classList.add("alert");
        fetchFailed.classList.add("alert-danger");
        fetchFailed.classList.add("text-center");
        var fetchFailedText = document.createTextNode(
          "An error occured when retrieving the contact information!"
        );
        var fetchFailedTryAgainContainer = document.createElement("div");
        fetchFailedTryAgainContainer.classList.add("d-flex");
        fetchFailedTryAgainContainer.classList.add("justify-content-center");
        var fetchFailedTryAgain = document.createElement("button");
        fetchFailedTryAgain.className = "btn btn-primary";
        fetchFailedTryAgain.id = "contactUsFetchFailedTryAgain";
        var fetchFailedTryAgainText = document.createTextNode("Try again");
        fetchFailedTryAgain.addEventListener("click", function () {
          loadContactUsInformation();
        });
        fetchFailed.appendChild(fetchFailedText);
        fetchFailedTryAgain.appendChild(fetchFailedTryAgainText);
        outermostDiv.appendChild(fetchFailed);
        fetchFailedTryAgainContainer.appendChild(fetchFailedTryAgain);
        outermostDiv.appendChild(fetchFailedTryAgainContainer);
        contactUsFlagContainer.appendChild(outermostDiv);
      }

      // All the flags into the contact us modal
      document
        .getElementById("contactUsAjaxTarget")!
        .appendChild(contactUsFlagContainer);
    }
  };
  let baseUrl;
  if (
    window.location.href.includes("test") ||
    window.location.href.includes("localhost")
  ) {
    // Some test env
    baseUrl =
      "https://disco-test.lowell.io/api/v1/cms/json?friendlyAppId=portal_login";
  } else {
    // Production
    baseUrl =
      "https://disco.lowell.io/api/v1/cms/json?friendlyAppId=portal_login";
  }

  xhttp.open("GET", baseUrl, true);
  xhttp.send();
}

$("#contactUsModalLink").on("click", function () {
  loadContactUsInformation();
  $("#contactUsModal").modal("show");
});

$(function () {
  $(document).on("click", "#newPortalModal .nav-link", function (e) {
    // Remove any "actives"
    $("#newPortalModal .nav-link").removeClass("active");
    if (!e.target.classList.contains("active")) {
      e.target.classList.add("active");
    }
    // In case we want custom button-texts
    const buttonCountry = e.target.dataset["country"];
    if (buttonCountry === "fi") {
      $("#closeNewPortalModalButton")[0].innerText = "Close";
    } else if (buttonCountry === "ee") {
      $("#closeNewPortalModalButton")[0].innerText = "Close";
    } else {
      $("#closeNewPortalModalButton")[0].innerText = "Close";
    }
  });

  $("[data-toggle=collapse]").on("click ", function (e) {
    e.preventDefault();
    if (
      $(this).hasClass("active") ||
      $("#languageGroup .collapsing").length > 0
    ) {
      e.stopPropagation();
    }
  });

  // Initialize everything
  if (!window.localStorage.getItem("showedNewPortalModal")) {
    // get browser lang
    const browserCountry = getBrowserCountry();
    console.log("Detected browser country:", browserCountry);
    // Expand correct BS-Collapse
    if (!browserCountry) {
      // Default collapse to show
      $("#collapse-fi").collapse("show");
    } else {
      if (browserCountry === "se") {
        $("#collapse-se").collapse("show");
        $("#newPortalModal .nav-link[data-country='se']")[0].classList.add(
          "active"
        );
        $("#btnAddProfilecloseNewPortalModalButton").prop("value", "Close");
      } else if (browserCountry === "no") {
        $("#collapse-no").collapse("show");
        $("#newPortalModal .nav-link[data-country='no']")[0].classList.add(
          "active"
        );
        $("#btnAddProfilecloseNewPortalModalButton").prop("value", "Close");
      } else if (browserCountry === "dk") {
        $("#collapse-dk").collapse("show");
        $("#newPortalModal .nav-link[data-country='dk']")[0].classList.add(
          "active"
        );
        $("#btnAddProfilecloseNewPortalModalButton").prop("value", "Close");
      } else {
        $("#collapse-fi").collapse("show");
        $("#newPortalModal .nav-link[data-country='fi']")[0].classList.add(
          "active"
        );
        $("#btnAddProfilecloseNewPortalModalButton").prop("value", "Close");
      }
    }
    $("#newPortalModal").modal({
      show: true,
      backdrop: "static",
    });
    window.localStorage.setItem("showedNewPortalModal", "true");
  }
});

function getBrowserCountry() {
  // Default country
  var browserCountry = "fi";
  // If IE8/9
  if (navigator.language) {
    var splitLocale = navigator.language.split("-");
    browserCountry = splitLocale[splitLocale.length - 1];
  }

  return browserCountry.toLowerCase();
}
